import {createApp} from 'vue';
import App from './App.vue';
import PrimeVue from 'primevue/config';
import TreeTable from 'primevue/treetable';
import Panel from 'primevue/panel';
import Column from 'primevue/column';
import Button from 'primevue/button';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';

import OverlayPanel from 'primevue/overlaypanel';

import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Divider from 'primevue/divider';
import InputText from 'primevue/inputtext';

import '/node_modules/primeflex/primeflex.css'
import 'primevue/resources/primevue.min.css';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primeicons/primeicons.css';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import Editor from 'primevue/editor';
import DataTable from 'primevue/datatable';
import ScrollPanel from 'primevue/scrollpanel';


//const cors = require("cors");
const app = createApp(App);

app.use(PrimeVue);
app.use(ToastService);
app.component('TreeTable', TreeTable);
app.component('DataTable', DataTable);
app.component('Panel', Panel);
app.component('Column', Column);
app.component('Button', Button);
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('Divider', Divider);
app.component('InputText', InputText);
app.component('OverlayPanel',OverlayPanel)
app.component('Splitter',Splitter)
app.component('SplitterPanel',SplitterPanel)
app.component('Editor',Editor)
app.component('Toast',Toast)
app.component('ScrollPanel',ScrollPanel)
//app.use(cors({origin: '*'}));

app.mount('#app');


//const express = require('express');

//solve cors issue
//const cors = require("cors");


