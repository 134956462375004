<template>

<ScrollPanel style="width: 100%; height: 40x" class="custombar4"  columnClasses="valign-top">
  <div class="flex justify-content-center flex-wrap w-full">
    <p class="text-4xl m-0 font-bold" style="padding-top: 20px !important ;padding-bottom: 0px !important">OpenSCV</p>
      
 <!-- <div class="flex justify-content-center flex-wrap w-full border-bottom-1 surface-border">
    <p class="text-xl m-1 font-italic">An Open Hierachical Taxonomy for Smart Contract Vulnerabilities</p>
  </div>-->
  </div>
                </ScrollPanel>

  <div>
    <TreeTable  :resizableColumns="true" columnResizeMode="expand"  scrollable selectionMode="single" scrollDirection="both"  v-model:selectionKeys="selectedKey1"   v-model:expanded-keys="expandedKeys" @node-select="onNodeSelect"
      @node-unselect="onNodeUnselect" :filters="filters" filterMode="lenient" :value="nodes" sortMode="single"
       class="p-treetable-sm" :paginator="false" :rows="10">
  <!-- <TreeTable columnResizeMode="fit"  :resizableColumns="true" columnResizeMode="fit" scrollHeight="90vw" scrollDirection="both"  selectionMode="single" v-model:selectionKeys="selectedKey1" @node-select="onNodeSelect"
      @node-unselect="onNodeUnselect" :filters="filters" filterMode="lenient" :value="nodes" sortMode="single"
      :resizableColumns="true" columnResizeMode="fit" class="p-treetable-sm" :paginator="true" :rows="10">--> 
      <template #header>
        <div style="text-align: right">
          <span>An Open Hierarchical Taxonomy for Smart Contract Vulnerabilities &nbsp &nbsp &nbsp</span>
          <i class="pi pi-search" style="margin: 4px 4px 0px 0px;"></i>
          <InputText v-model="filters['global']" placeholder="Search" size="50" />
          <Button v-if="false" @click="showEditorGlobal($event)" type="button" icon="pi pi-pencil" class="p-button-warning"></Button>
        </div>
      </template>

     

      <Column expander frozen field="index" header="Index" :expander="true" :sortable="true" style="width: 170px"></Column>
      <Column field="description" header="Description" bodyClass="text-justify" :sortable="true" style="width: 400px"></Column>
      <Column field="swc" header="SWC" :sortable="false" style="width: 80px "></Column>
      <Column field="dasp" header="DASP" :sortable="false" style="width: 80px"></Column>
      <Column field="rameder" header="Rameder" :sortable="false" style="width: 80px"></Column>
      <Column field="cwe" header="CWE" :sortable="true" style="width: 80px"></Column>
      <Column field="vulnerabname" header="Synonyms" :sortable="false" style="width: 170px"></Column>
      <Column field="defecttype" header="Defect Type" :sortable="true" style="width: 160px"></Column>
      <Column field="qualifier" header="Qualifier" :sortable="true" style="width: 100px"></Column>
      <Column header="Action" headerStyle="width: 5rem" headerClass="text-center" bodyClass="text-center">

        <template #body="slotProps">
          <Button @click="showDetail(slotProps, $event)" v-if="slotProps.node.data.type == 'v'" type="button"
            icon="pi pi-search" class="p-button-success" style="margin-right: .2em"></Button>

        <!--  <Button  @click="showEditor(slotProps, $event)" v-if="slotProps.node.data.type == 'v'" type="button"
            icon="pi pi-pencil" class="p-button-warning" style="margin-right: .2em"></Button>-->

          <Button @click="showReference(slotProps, $event)" v-if="slotProps.node.data.type == 'v'" type="button"
            icon="pi pi-book" class="p-button" style="margin-right: .2em"></Button>
        </template>
      </Column>


      <template #footer>

      </template>
    </TreeTable>

    <OverlayPanel :showCloseIcon="true" :dismissable="true" ref="op">
      <div style="width: 750px; height: 490px; margin-top: 0px;">
      <!-- <Panel :header="selNodeIndex">-->
        <span class="border-3 border-blue-500 w-42rem h-3rem m-2 surface-overlay font-bold flex align-items-center justify-content-center">
                  {{selNodeIndex}}
                </span>
           
        <div class="flex">
          <div>
            <Editor  @load="loadEditorVul" @text-change="getCodeVul($event)" title="Vulnerable" v-model="sourceVulnerable"
              editorStyle="height: 410px; width:355px" :readonly=false>
              <template v-slot:toolbar>
                <span>
                  Vulnerable Code
                </span>
              </template>
            </Editor>

          </div>
          <Divider layout="vertical" />
          <div>
            <Editor  @load="loadEditor" @text-change="getCode($event)" title="Vulnerable" v-model="sourceFixed"
              editorStyle="height: 410px; width:355px" :readOnly=false>
              <template v-slot:toolbar>
                <span>
                  Fixed Code
                </span>
              </template>
            </Editor>

          </div>

        </div>
     <!-- <Button label="Salvar" @click="Salvar()"></Button>
      </Panel>-->
      </div>
    </OverlayPanel>

    <OverlayPanel :showCloseIcon="true" :dismissable="true" ref="op3">
      <span class="border-3 border-blue-800 w-42rem h-3rem m-2 surface-overlay font-bold flex align-items-center justify-content-center">
                  {{selNodeIndex}}
                </span>
      <div style="width: 1000px; height: 500px; margin-top: 0px;">
        <div class="flex">
          <div>
            <DataTable :class="p-datatable-sm" :value="referenceData" paginator :rows="5">
              <Column field="toolname" header="Source"></Column>
              <Column field="textreference" header="Reference"></Column>
            </DataTable>

          </div>
        </div>
      </div>
    </OverlayPanel>

    <OverlayPanel :showCloseIcon="true" :dismissable="true" ref="op2" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '450px' }">
      <div class="flex justify-content-center flex-wrap w-full">
        <p class="text-4xl m-1 font-bold">Report an issue</p>
      </div>
      <Editor v-model="issueText" editorStyle="height: 320px" />
      <Divider />
      <Toast position="center" />
      <Button label="Submit" v-on:click="openIssue()" />


    </OverlayPanel>

    <div class="card">
        <div class="flex flex-column md:flex-row gap-5">
            <div class="flex-auto">
                <ScrollPanel style="width: 100%; height: 120px" class="custombar2">
                    <p>
                      <b>The information in https://openscv.dei.uc.pt is based on the paper:</b>
                     </p>
                    <p>
                      Vidal, F.R., Ivaki, N. & Laranjeiro, N. OpenSCV: an open hierarchical taxonomy for smart contract vulnerabilities. Empirical Software Engineering 29, 101 (2024) <a href="https://rdcu.be/dLd8G">EMSE Journal</a>.
                     </p>
                </ScrollPanel>
            </div>
            <div class="flex-auto">
                <ScrollPanel style="width: 100%; height: 120px" class="custombar2">
                    <p>
                      <b>The data used to create this taxonomy is available at:</b>
                    </p>
                    <p>
                      Vidal Fernando Richter, Naghmeh Ivaki, and Nuno Laranjeiro. 2023. "OpenSCV: An Open Hierachical Taxonomy for Smart Contract Vulnerabilities – Supplemental Material". <a href="https://doi.org/10.5281/zenodo.7763982">DOI 10.5281/zenodo.7763982</a>.
                     </p>
                </ScrollPanel>
            </div>
            <div class="flex-auto">
                <ScrollPanel style="width: 100%; height: 120px" class="custombar2">
                    <p>
                      <b>Github repository is available at:</b>
                    </p>
                    <p>
                      Vidal F, Ivaki N, Laranjeiro N (2023b) OpenSCV Github Repository.  <a href="https://github.com/blockchain-dei/openscv ">OpenSCV Github</a>    </p>
                </ScrollPanel>
            </div>
        </div>
  
 
    <p class="m-0">
      {{gitLastRelease}}    </p>


    </div>






  </div>
</template>


<script>

import OpenScvService from './service/openscvservice';
import moment, { parseTwoDigitYear } from 'moment';

export default {
  data() {
    return {
      filters: {},
      nodes: null,
      sourceFixed: null,
      sourceVulnerable: null,
      sourceFixedHtml: null,
      sourceVulnerableHtml: null,
      issueText: null,
      sourceCode: null,
      referenceData: null,
      gitLastRelease: null,
      expandedKeys: {}
    }

  },
  OpenScvService: null,
  created() {
    this.OpenScvService = new OpenScvService();
  },
  mounted() {
    this.OpenScvService.getTreeTableNodes().then(data => {
      this.nodes = data[0]
      console.log("nodes "+ data[0]);
      data[1].forEach( (element) =>
               {
             let _expandedKeys = { ...this.expandedKeys };
             _expandedKeys[element] = true;
             this.expandedKeys = _expandedKeys;
       //         console.log(element)
               }
        );

     // console.log(data)
    });

   // this.expandAllNodes();

     this.OpenScvService.getLastVersion().then(data =>
     {
      //console.log(data.published_at);
        const dateConverted = new Date(data.published_at);
      //  console.log(dateConverted);
        this.gitLastRelease ="Last update: " + dateConverted.toLocaleDateString('en-US',{
          month:'long',
          year:'numeric'
        })
      //  console.log(data); // + data.tag_name + " " +
      //  console.log(this.gitLastRelease )
     });




  },
  methods: {
    onNodeSelect(node) {
      this.selNodeIndex = node.data.index;

    },
    onNodeUnselect(node) {
      this.selNodeIndex = node.data.index;
    },
    expandAllNodes() {
            let _expandedKeys = { ...this.expandedKeys };
            _expandedKeys['1'] = true;
            _expandedKeys['1-1'] = true;

         
            this.expandedKeys = _expandedKeys;
        },
    showDetail(slotProps, event) {
      this.selNodeIndex = slotProps.node.data.index
      this.OpenScvService.getSourceCode(this.selNodeIndex, "Fixed").then(data => { this.sourceFixed = data });
      this.OpenScvService.getSourceCode(this.selNodeIndex, "Vulnerable").then(data => { this.sourceVulnerable = data });
      //this.sourceCode =  this.OpenScvService.getSourceCode(this.selNodeIndex, "Fixed");
      // console.log(this.sourceCode)
      this.$refs.op.toggle(event);
    },
    showReference(slotProps, event) {
      this.selNodeIndex = slotProps.node.data.index
      this.OpenScvService.getReference(this.selNodeIndex).then(data => {
         this.referenceData = data 
         //console.log(JSON.parse(this.referenceData))
        });
      this.$refs.op3.toggle(event);
    },
    loadEditorVul() {
      this.sourceFixedHtml = null;
      this.sourceVulnerableHtml = null;
    },
    loadEditor() {
      this.sourceFixedHtml = null;
      this.sourceVulnerableHtml = null;
    },
    Salvar() {
      if (this.sourceFixedHtml == null) {
        alert('Salve o Fixed')
        return
      }

      if (this.sourceVulnerableHtml == null) {
        alert('Salve o Vulnerab')
        return
      }
      this.OpenScvService.saveSource(this.selNodeIndex, this.sourceFixedHtml, this.sourceVulnerableHtml).then(data => console.log("OK" + data))

    },
    showEditor(slotProps, event) {
      this.selNodeIndex = slotProps.node.data.index
      this.issueType = "level"
      this.$refs.op2.toggle(event);
    },
    showEditorGlobal(event) {
      this.issueType = "global"
      this.$refs.op2.toggle(event);
    },
    getCode(event) {
      this.sourceFixedHtml = event.htmlValue;
    },
    getCodeVul(event) {
      this.sourceVulnerableHtml = event.htmlValue;
    },

    async openIssue() {
      if (this.issueText == "undefined") {
        this.$toast.add({ severity: 'danger', summary: 'Info Message', detail: 'Empty text not allowed here', life: 15000 });

        return;
      }
      if (this.issueText == "" || this.issueText == null) {

        this.$toast.add({ severity: 'error', summary: 'Info Message', detail: 'Empty text not allowed here', life: 15000 });
        return;
      }
     // console.log(this.issueText);
      var ret = "";
      var vText = "";
      if (this.issueType == "global")
        vText = "Global"
      else
        vText = this.selNodeIndex
      let vDateFormat = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      console.log(vDateFormat)
      vText = vDateFormat + " " + vText;
      ret = await this.OpenScvService.openIssue(vText, this.issueText);
      this.issueText = "";
      // this.$refs.op2.hide();


      this.$toast.add({ severity: 'info', summary: 'Info Message', detail: 'Issue created with success ! \n\n ' + ret, life: 15000 });


    }


  }
}
</script>

<style scoped lang="scss">
@import "App.scss";
</style>
