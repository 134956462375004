export class Data {
    index: string
    description: string
    qualifier?: string
    defecttype?: string
    impact: string
    rameder:string
    cwe: string
    swc: string
    dasp: string
    type: string
    vulnerabname:string
    defectdefecttype?: string
  }