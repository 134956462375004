import axios from 'axios'
import { JsonSerializer } from 'typescript-json-serializer';
import { treeOpenSCV } from '@/model/treeOpenSCV'
import { Root } from '@/model/Root'
import { Data } from '@/model/Data'
import { Children } from '@/model/Children'

export default class OpenScvService {
  private ip='https://openscv.dei.uc.pt';
 // private ip='http://localhost:443';
  async saveSource(p_index:string, p_fixed:string,p_vulnerab:string)
  {
    const myArray = p_index.split(" ");
    let data;
    let word = myArray[0];
    await axios
    .get(this.ip+'/setSourceCode', {params: { word, p_fixed, p_vulnerab}} ) 
    .then(resp => { data = resp})
    console.log(data)
    return data
  }

  async getReference(p_index:string)
  {
    const myArray = p_index.split(" ");
    let word = myArray[0];
    let data:string="";
    let lineL1;
    //console.log(word)
    await axios
    .get(this.ip+'/getReference', {params: { word}} ) 
    .then(resp =>
      {
        lineL1 = resp.data
         // Array.from(resp.data).forEach(element => {
         // lineL1 = JSON.parse(JSON.stringify(element));
         // data = data + '\n'+  lineL1['textreference'];
        //  }
         // );
         

      }
      )
     // console.log(lineL1)

     
      return lineL1;
  }



  async getSourceCode(p_index:string ,p_type: string)
  {
    const myArray = p_index.split(" ");
    let word = myArray[0];
    let data:string="";
    let lineL1;
    //console.log(word)
    await axios
    .get(this.ip+'/getSourceCode', {params: { word, p_type}} ) 
    .then(resp =>
      {

          Array.from(resp.data).forEach(element => {
          lineL1 = JSON.parse(JSON.stringify(element));
          if (p_type == "Fixed")
            data = lineL1['fixed'];
          else 
            data = lineL1['vulnerable'];
          }

          );
         

      }
      )
     // console.log(lineL1)

     
      return data;// lineL1;
  }

  async getLastVersion()
  {
    var data;
    let config = {
    headers: {
        "Authorization": "Bearer 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",
        "Accept": "application/vnd.github+json"
       }

  
    };
    
    
    await axios.get("https://api.github.com/repos/blockchain-dei/openscv/releases/latest")
      .then(response => { data = response.data});

     // console.log(data);
     return data;
  }

  async openIssue(p_index:string, p_text: string) {

    //axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    var data;

    const detail = {
      "title": p_index,
      "body": p_text
    };
    const headers = {
      "Authorization": "Bearer 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",
      "Accept": "application/vnd.github+json"

  
    };
    
    
       await axios.post("https://api.github.com/repos/Dicipulofer/openscv/issues", detail, { headers })
      .then(response => data = response.data.url);

     return data;

  }

  async getTreeTableNodes() {
    // Instantiate a default serializer
    const defaultSerializer = new JsonSerializer();
    var data;

    const keysArray = ['0'];

    await axios
      .get(this.ip+'/getDefects')
      .then((resp) => {


        //  var objTree = new treeOpenSCV();
        var objRoot: Root[] = [];


       console.log(resp);


        //https://www.npmjs.com/package/typescript-json-serializer

        /////////////////////////////////////////////
        //////////// LEVEL 1////////////////////////
        ////////////////////////////////////////////
    
        Array.from(resp.data).forEach(element => {

          let lineL1 = JSON.parse(JSON.stringify(element));
          if (lineL1['level'] == 1) {

            let objChildrenL1: Children[] = [];
            let objDataL1 = new Data();
            let indexL1 = JSON.stringify(lineL1['index']).replace(/["]/g, '');

            objDataL1.index = lineL1['index'] + ' ' + lineL1['defectname'];
            objDataL1.description = lineL1['description'];
            objDataL1.defecttype = lineL1['defecttype'];
            objDataL1.qualifier = lineL1['qualifier'];
            objDataL1.impact = lineL1['impact'];
            objDataL1.rameder = lineL1['rameder'];
            objDataL1.type = lineL1['type'];
            objDataL1.cwe = lineL1['cwe'];
            objDataL1.dasp = lineL1['dasp'];
            objDataL1.swc = lineL1['swc'];
            objDataL1.vulnerabname = lineL1['vulnerabname'];

            /////////////////////////////////////////////
            //////////// LEVEL 2 ////////////////////////
            ////////////////////////////////////////////

            Array.from(resp.data).forEach(element => {
              let lineL2 = JSON.parse(JSON.stringify(element));
              if (lineL2['level'] == 2) {

                let objChildrenL2: Children[] = [];
                let objDataL2 = new Data();
                let indexL2 = JSON.stringify(lineL2['index']).replace(/["]/g, '');

                let matchIndex = false;
                if (indexL1 == indexL2.substring(0, indexL1.length))
                  matchIndex = true;

                if (matchIndex) {
                  //console.log('entrei level 2 ' + lineL1['index'] + ' ' + lineL2['index']);
                  objDataL2.index = lineL2['index'] + ' ' + lineL2['defectname'];
                  objDataL2.description = lineL2['description'];
                  objDataL2.defecttype = lineL2['defecttype'];
                  objDataL2.qualifier = lineL2['qualifier'];
                  objDataL2.impact = lineL2['impact'];
                  objDataL2.rameder = lineL2['rameder'];
                  objDataL2.type = lineL2['type'];
                  objDataL2.cwe = lineL2['cwe'];
                  objDataL2.dasp = lineL2['dasp'];
                  objDataL2.swc = lineL2['swc'];
                  objDataL2.vulnerabname = lineL2['vulnerabname'];

                  /////////////////////////////////////////////
                  //////////// LEVEL 3 ////////////////////////
                  ////////////////////////////////////////////
                  Array.from(resp.data).forEach(element => {
                    let lineL3 = JSON.parse(JSON.stringify(element));
                    if (lineL3['level'] == 3) {

                      let objChildrenL3: Children[] = [];
                      let objDataL3 = new Data();
                      let indexL3 = JSON.stringify(lineL3['index']).replace(/["]/g, '');

                      let matchIndex = false;

                      if (indexL2 == indexL3.substring(0, indexL2.length))
                        matchIndex = true;
                     // console.log(lineL3['index'].split('.')[1]);
                     // console.log(lineL2['index'].split('.')[1]);

                      if (lineL2['index'].split('.')[1] != lineL3['index'].split('.')[1])
                        matchIndex = false;

                      if (matchIndex) {
                        //console.log('entrei level 3 ' + lineL2['index'] + ' ' + lineL3['index']);
                        objDataL3.index = lineL3['index'] + ' ' + lineL3['defectname'];
                        objDataL3.description = lineL3['description'];
                        objDataL3.defecttype = lineL3['defecttype'];
                        objDataL3.qualifier = lineL3['qualifier'];
                        objDataL3.impact = lineL3['impact'];
                        objDataL3.type = lineL3['type'];
                        objDataL3.rameder = lineL3['rameder'];
                        objDataL3.cwe = lineL3['cwe'];
                        objDataL3.dasp = lineL3['dasp'];
                        objDataL3.swc = lineL3['swc'];
                        objDataL3.vulnerabname = lineL3['vulnerabname'];



                  /////////////////////////////////////////////
                  //////////// LEVEL 4 ////////////////////////
                  ////////////////////////////////////////////
                  Array.from(resp.data).forEach(element => {
                    let lineL4 = JSON.parse(JSON.stringify(element));
                    if (lineL4['level'] == 4) {

                      let objChildrenL4: Children[] = [];
                      let objDataL4 = new Data();
                      let indexL4 = JSON.stringify(lineL4['index']).replace(/["]/g, '');

                      let matchIndex = false;

                      if (indexL3 == indexL4.substring(0, indexL3.length))
                        matchIndex = true;
                      // console.log(lineL4['index'].split('.')[1]);
                       // console.log(lineL3['index'].split('.')[1]);

                      if (lineL3['index'].split('.')[1] != lineL4['index'].split('.')[1])
                        matchIndex = false;

                      if (matchIndex) {
                       // console.log('entrei level 4 ' + lineL3['index'] + ' ' + lineL4['index']);
                        objDataL4.index = lineL4['index'] + ' ' + lineL4['defectname'];
                        objDataL4.description = lineL4['description'];
                        objDataL4.defecttype = lineL4['defecttype'];
                        objDataL4.qualifier = lineL4['qualifier'];
                        objDataL4.impact = lineL4['impact'];
                        objDataL4.type = lineL4['type'];
                        objDataL4.rameder = lineL4['rameder'];
                        objDataL4.cwe = lineL4['cwe'];
                        objDataL4.dasp = lineL4['dasp'];
                        objDataL4.swc = lineL4['swc'];
                        objDataL4.vulnerabname = lineL4['vulnerabname'];

                        let objChildrenSingle = new Children();
                        objChildrenSingle.key = JSON.stringify(lineL4['index']).replaceAll('.', '-').replace(/["]/g, '');
                        keysArray.push(objChildrenSingle.key);
                        objChildrenSingle.data = objDataL4
                        objChildrenL3.push(objChildrenSingle);

                      }




                    }
                  })


                        let objChildrenSingle = new Children();
                        objChildrenSingle.key = JSON.stringify(lineL3['index']).replaceAll('.', '-').replace(/["]/g, '');
                        keysArray.push(objChildrenSingle.key);
                        objChildrenSingle.data =  objDataL3
                        objChildrenSingle.children = objChildrenL3;
                        objChildrenL2.push(objChildrenSingle);

                      }




                    }
                  })

                  let objChildrenSingle = new Children();
                  objChildrenSingle.key = JSON.stringify(lineL2['index']).replaceAll('.', '-').replace(/["]/g, '');
                  keysArray.push(objChildrenSingle.key);
                  objChildrenSingle.data = objDataL2
                  objChildrenSingle.children = objChildrenL2
                  objChildrenL1.push(objChildrenSingle);


                }
              }

            });


            let objRootSingle = new Root();
            objRootSingle.key = JSON.stringify(lineL1['index']).replaceAll('.', '-').replace(/["]/g, '');
            keysArray.push(objRootSingle.key);
            objRootSingle.data = objDataL1;

            objRootSingle.children = objChildrenL1;

            objRoot.push(objRootSingle);

          }
        }

        );

        //objTree.root = objRoot;
        data = defaultSerializer.serialize(objRoot);

       // keysArray.forEach( (element) =>
          //console.log(element)
       // );

        
       
       // console.log(keysArray);
        // return JSON.parse((JSON.stringify(data)))

        //   return JSON.stringify(data);
        // console.log(this.data)

      })



    // console.log(JSON.stringify(jsObject, null, 3));



    // });
    //     return JSON.parse(data);
    // console.log(data)
    return [data, keysArray];
    // return fetch('/data/openscvdata.json').then(res => res.json())
    // .then( d => d.root);
  }

 

}